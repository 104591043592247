import { Flex, Heading, Text } from "@radix-ui/themes";
import { PageType } from "../Layout";
import MobileSidebar from "./MobileSidebar";

interface MobileLayoutProps {
  children: React.ReactNode;
  page: PageType;
  title: string | React.ReactNode;
  wide?: boolean;
}

const MobileLayout = ({ children, page, title, wide }: MobileLayoutProps) => {
  return (
    <Flex direction="column" style={{maxWidth: '100vw', overflowX: "hidden", position: "relative"}}>
      <Flex direction="column" mx={wide ? "" : "5"} maxWidth="100vw" pb="9">
        {title && (
          <Heading size="8" my="5">{title}</Heading>
        )}
        {children}
      </Flex>
      <Flex className="bottom">
        <MobileSidebar page={page} />
      </Flex>
    </Flex>
  );
};

export default MobileLayout;
