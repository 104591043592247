"use client";

import MobileLayout from "./Layout/MobileLayout";
import Sidebar from "./Layout/Sidebar";

export type PageType =
  | "campaign"
  | "artist"
  | "users"
  | "creator"
  | "agency"
  | "scraper-accounts"
  | "settings"
  | "dashboard"
  | "people"


interface Props {
  children: React.ReactNode;
  page: PageType;
  title: string | React.ReactNode;
}
const Layout = ({ children, page, title }: Props) => {
  return (
    <>
      <div className="lg:hidden">
        <MobileLayout page={page} title={title}>
          {children}
        </MobileLayout>
      </div>
      <div className="hidden md:hidden lg:block">
        <DesktopLayout page={page} title={title}>
          {children}
        </DesktopLayout>
      </div>
    </>
  );
};

const DesktopLayout = ({ children, page, title }: Props) => {
  return (
    <div className="text-main-text flex">
      <Sidebar page={page} />
      <div className="m-5 flex flex-col w-full ml-40 mt-0 pr-5">
        <div className="text-4xl font-semibold py-5">{title}</div>
        <main className="mr-4">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
