"use client";
import useUser from "@/lib/useUser";
import { capitalize, logoUrl } from "@/lib/util";
import Link from "next/link";
import { useEffect, useState } from "react";
import { PageType } from "../Layout";
import { sidebarLinks } from "./links";
import { logout } from "./common";
import { IconLogout2 } from "@tabler/icons-react";
import Image from "next/image";
// import { ReactComponent as NavigationShape } from "../../public/Navigation-shape.svg"
import { Flex, Tooltip } from "@radix-ui/themes";

interface NavLinkProps {
  href: string;
  active: boolean;
  hero: any;
  name: string;
}
const NavigationShape = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
    viewBox="-9.1 -6 100 90"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M48 60c11.046 0 20 8.954 20 20V0c0 11.046-8.954 20-20 20H20C8.954 20 0 28.954 0 40s8.954 20 20 20h28Z"
      clipRule="evenodd"
    />
  </svg>
)

const NavLink = ({ href, active, hero, name }: NavLinkProps) => (
  <div className={`overlay-wrap ${active ? "active": ""}`} style={{height: "112px", width: "96px"}}>
    <Link href={href}>
      <NavigationShape 
      className={`overlay-img ${active ? "active" : ""}`}
      />
        <Flex className="overlay" style={{paddingTop: "45px", paddingLeft: "35px"}}>
          <Tooltip content={name}>
            {hero}
          </Tooltip>
        </Flex>
    </Link>
  </div>
);
const Sidebar = ({ page }: { page: PageType }) => {
  const [logo, setLogo] = useState("");
  const [invert, setInvert] = useState(false)
  

  useEffect(() => {
    setLogo(window.location.host);
  }, []);

  const { checkRoles, mutateUser } = useUser();
  useEffect(() => {
    const listenStorageChange = () => {
      const localTheme = localStorage.getItem("theme")
      if (localTheme === null) {
        setInvert(false)
      } else {
        const newInvert = localTheme=== "light" || localTheme === "white"  || localTheme  === "blue" ? true : false
        setInvert(newInvert)

      }
    };
    listenStorageChange();
    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);
  console.log("invert", invert)
  
  return (
    <div
      className="layout m-5 w-24 flex flex-col justify-between fixed"
      style={{ height: "calc(100vh - 40px)" }}
    >
      <Flex direction="column">
        <Flex mx="auto" mt="8" width="14">
        <Link href="/">
          <Image  
            src={logoUrl(logo, invert)}
            alt="Logo"
            width={55}
            height={50}
          />
        </Link>
        </Flex>
        {sidebarLinks(checkRoles).map((link) =>
          link ? (
            <Tooltip content={capitalize(link.page)}>
            <NavLink
              key={link.page}
              active={page === link.page}
              href={link.href}
              hero={link.hero}
              name={link.title}
            />
            </Tooltip>
          ) : null,
        )}
      </Flex>
      <div className="flex items-center justify-center mb-4">
        <IconLogout2
          className="icon-dark cursor-pointer"
          onClick={logout(mutateUser)}
        />
      </div>
    </div>
  );
};

export default Sidebar;
