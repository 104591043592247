import { IconGraph, IconHome, IconSettings, IconUsersGroup } from "@tabler/icons-react";

export const sidebarLinks = (checkRoles: any) => [
  { page: "dashboard", href: "/", title: "Home", hero: <IconHome className="icon-dark"/> },
  {
    page: "campaign",
    href: "/campaign",
    title: "Campaigns",
    hero: <IconGraph className="icon-dark"/>,
  },
  checkRoles(["agency-admin", "agency-user", "superadmin"]) && {
    page: "people",
    href: "/people",
    title: "People",
    hero: <IconUsersGroup className="icon-dark"/>,
  },
  // checkRoles(["agency-admin", "agency-user", "superadmin"]) && {
  //   page: "artist",
  //   href: "/artist",
  //   title: "Artists",
  //   hero: <IconMusic className="icon-dark" />,
  // },
  // checkRoles(["agency-admin", "superadmin"]) && {
  //   page: "users",
  //   href: "/users",
  //   title: "Users",
  //   hero: <IconUsersGroup className="icon-dark"/>,
  // },
  // checkRoles(["agency-admin", "agency-user", "superadmin"]) && {
  //   page: "creator",
  //   href: "/creators",
  //   title: "Creators",
  //   hero: <IconHash className="icon-dark"/>,
  // },
  // checkRoles(["superadmin"]) && {
  //   page: "agency",
  //   href: "/agencies",
  //   title: "Agencies",
  //   hero: <IconBuilding className="icon-dark"/>,
  // },
  // checkRoles(["superadmin"]) && {
  //   page: "scraper-accounts",
  //   href: "/admin/scraping/accounts",
  //   title: "Scrapers",
  //   hero: <IconTool className="icon-dark"/>,
  // },
  {
    page: "settings",
    href: "/me",
    title: "Settings",
    hero: <IconSettings className="icon-dark"/>,
  },
];
